import { Helmet } from 'react-helmet-async';
import ogImage from '../images/cover.jpg';

export const Seo = ({
                        title,
                        description,
                        keywords,
                        meta = [],
                        link = []
                    }) => {
    return (
        <Helmet
            htmlAttributes={{ prefix: 'og: //ogp.me/ns#' }}
            title={title}
            meta={[
                {
                    name: 'description',
                    content: description
                },
                {
                    name: 'keywords',
                    content: keywords
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:title',
                    content: title
                },
                {
                    property: 'og:description',
                    content: description
                },
                {
                    property: 'og:site_name',
                    content: 'Уличные ковры'
                },
                {
                    property: 'og:image',
                    content: ogImage
                },
                {
                    property: 'og:image:width',
                    content: '1300'
                },
                {
                    property: 'og:image:height',
                    content: '630'
                },
                {
                    property: 'og:locale',
                    content: 'ru'
                },
                {
                    property: 'twitter:title',
                    content: title
                },
                {
                    property: 'twitter:description',
                    content: description
                },
                {
                    property: 'twitter:card',
                    content: 'summary_large_image'
                },
                {
                    property: 'twitter:image',
                    content: ogImage
                },
                {
                    property: 'vk:image',
                    content: ogImage
                }
            ]}
        />
    )
}