import React from 'react';
import classes from './Product.module.css';
import topImage from '../../images/product-gallery-stone-top.jpg';
import bottomImage from '../../images/product-gallery-stone-bottom.jpg';
import { Gallery } from '../../components/UI/gallery/Gallery';
import { SectionHeading } from '../../components/UI/section-heading/SectionHeading';
import { ProductForm } from '../../components/UI/product-form/ProductForm';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { TextWithIcon } from '../../components/UI/text-with-icon/TextWithIcon';
import flower from '../../images/product-icon-flower.svg';
import chainLinks from '../../images/product-icon-chain-links.svg';
import screwdriverAndWrench from '../../images/product-icon-screwdriver-and-wrench.svg';
import parkBench from '../../images/product-icon-park-bench.svg';
import fountain from '../../images/product-icon-fountain.svg';
import parkedCar from '../../images/product-icon-parked-car.svg';
import houseWithTrees from '../../images/product-icon-house-with-trees.svg';
import cemetery from '../../images/product-icon-cemetery.svg';
import { useCheckMobileScreen } from '../../hooks/useCheckMobileScreen';
import { breakPointMobile } from '../../helpers/constants';
import { Seo } from '../../components/Seo';
import { centeredInScrollView } from '../../helpers/constants';

export const Stone = () => {
    const isMobile = useCheckMobileScreen(breakPointMobile);
    return (
        <>
            <section className={(classes.Product)}>
                {isMobile ? null : <Gallery
                    topImage={{
                        link: topImage,
                        alt: 'Плитка каменного ковра, вид сбоку.'
                    }}
                    bottomImage={{
                        link: bottomImage,
                        alt: 'Процесс укладки каменного ковра рядом с бассейном.'
                    }}
                />}
                <div className={classes.container}>
                    <SectionHeading
                        title={'Каменный ковёр'}
                        description={'Эластичное монолитное покрытие из натуральных камней: морской или речной гальки, кварца, мраморной или гранитной крошки разных фракций'}
                    />
                    {isMobile ? <Gallery
                        topImage={{
                            link: topImage,
                            alt: 'Плитка каменного ковра, вид сбоку.'
                        }}
                        bottomImage={{
                            link: bottomImage,
                            alt: 'Процесс укладки каменного ковра рядом с бассейном.'
                        }}
                    /> : null}
                    <ProductForm
                        product={'Каменный ковёр'}
                    />
                    <Tabs
                        className={classes.tabsContainer}
                        selectedTabClassName={classes.tabActive}
                        selectedTabPanelClassName={classes.tabContent}
                    >
                        <TabList className={classes.tabs} onClick={centeredInScrollView}>
                            <Tab className={classes.tabName}>Стоимость</Tab>
                            <Tab className={classes.tabName}>Характеристики</Tab>
                            <Tab className={classes.tabName}>Применение</Tab>
                            <Tab className={classes.tabName}>Гарантия</Tab>
                        </TabList>

                        <TabPanel className={classes.tabPrice}>
                            <ul className={classes.stone}>
                                <li>Стоимость работ зависит от выбранного материала.</li>
                                <li className={classes.price}>Средняя цена ≈ 4100 руб.</li>
                            </ul>

                            <ul className={classes.footnotes}>
                                <li>* стоимость указана за 1 кв. м толщиной 10 мм</li>
                                <li>** стоимость доставки исчисляется по тарифам транспортной компании на дату
                                    заключения
                                    договора
                                </li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <Tabs
                                className={classes.tabNav}
                                selectedTabClassName={classes.tabNavActive}
                                selectedTabPanelClassName={classes.tabNavContent}
                            >
                                <TabList className={classes.tabNavList}>
                                    <Tab className={classes.tabNavName}>Внешний вид</Tab>
                                    <Tab className={classes.tabNavName}>Долговечность и прочность</Tab>
                                    <Tab className={classes.tabNavName}>Монтаж</Tab>
                                </TabList>
                                <TabPanel>
                                    <TextWithIcon
                                        icon={{ link: flower, alt: 'Иконка с изображением распустившегося цветка' }}
                                        text={{
                                            description: 'Покрытие обладает высокими визуальными показателями. Идеально впишется в любой ландшафт.',
                                            extended: 'Натуральные природные камни в виде остатков окаменевшей вулканической магмы, базальта и туфы, ' +
                                                'зародившийся в самом жерле вулкана гранит. Та галька, которой мы играли на пляже, пускали ' +
                                                'блинчики по воде и строили замки - самая настоящая горная порода, свидетельница доисторических времен.'
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <TextWithIcon
                                        icon={{
                                            link: chainLinks,
                                            alt: 'Иконка с изображением не рвущихся звеньев цепи'
                                        }}
                                        text={{
                                            description: 'Очень прочное, устойчивое к нагрузкам и перепадам температуры, ' +
                                                'которое, в зависимости от места монтажа может иметь высокие дренажные ' +
                                                'свойства или использоваться для гидроизоляции.',
                                            extended: 'Практически полная водонепроницаемость покрытия и, соответственно, высокая морозостойкость.'
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <TextWithIcon
                                        icon={{
                                            link: screwdriverAndWrench,
                                            alt: 'Иконка с изображением скрещенных гаечного ключа и отвёртки'
                                        }}
                                        text={{
                                            description: 'Монтируется на бетон, асфальт, плитку, стены, металл, стекло ' +
                                                'и пеноплекс монолитным бесшовным способом.'
                                        }}
                                    />
                                </TabPanel>
                            </Tabs>
                        </TabPanel>
                        <TabPanel className={classes.use}>
                            <TextWithIcon
                                icon={{
                                    link: parkBench,
                                    alt: 'Иконка с изображением двух деревьев и скамейки'
                                }}
                                text={{ description: 'Зоны отдыха, пешеходные дорожки' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: parkedCar,
                                    alt: 'Иконка с изображением припаркованного автомобиля'
                                }}
                                text={{ description: 'Зона паркинга' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: fountain,
                                    alt: 'Иконка с изображением работающего фонтана'
                                }}
                                text={{ description: 'Причалы, зоны возле бассейнов, фонтанов' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: cemetery,
                                    alt: 'Иконка с изображением арочных ворот'
                                }}
                                text={{ description: 'Входные группы' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: houseWithTrees,
                                    alt: 'Иконка с изображением дома, с деревьями позади'
                                }}
                                text={{ description: 'Декорирование элементов благоустройства: фасады, заборы, двери' }}
                            />
                        </TabPanel>
                        <TabPanel className={classes.warranty}>
                            <h2 className={classes.warrantyHeading}>STREET COVER STORE ценит свою репутацию!</h2>
                            <p className={classes.warrantySubheading}>Если в течение 24 месяцев при соблюдении
                                рекомендаций
                                произойдет деформация покрытия, мы
                                безвозмездно проведём реставрационные работы.</p>
                        </TabPanel>
                    </Tabs>
                </div>
            </section>
            <Seo
                title={'Каменный ковёр'}
                description={'Эластичное монолитное покрытие из натуральных камней: морской или речной гальки, кварца, мраморной или гранитной крошки разных фракций'}
                keywords={'каменный ковёр, каменный ковёр уличное покрытие'}
            />
        </>
    );
};