import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Logo.module.css';
import { useLocation } from 'react-router-dom';

export const Logo = () => {
    const location = useLocation();
    let isHomePage = location.pathname === '/';
    return (
        <Link to={'/'} className={isHomePage ? `${classes.Logo} ${classes.inactive}` : classes.Logo} />
    );
};