import React from 'react';
import classes from './SectionHeading.module.css';

export const SectionHeading = ({ title, description }) => {
    return (
        <article className={classes.SectionHeader}>
            <h1 className={classes.heading}>{title}</h1>
            {
                description
                    ? <p className={classes.subheading}>{description}.</p>
                    : null
            }

        </article>
    );
};