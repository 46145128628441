import React from 'react';
import classes from './Footer.module.css';
import { Link } from 'react-router-dom';

export const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer className={classes.Footer}>
            <nav className={classes.nav}>
                <Link to={'/'} className={classes.link}>STREET COVER STORE {year} г.</Link>
                <Link to={'/privacy'} className={classes.link}>Политика конфиденциальности</Link>
                <Link to={'/terms'} className={classes.link}>Правила использования сайта</Link>
            </nav>
            <a
                href="https://rjadysh.com/?utm_source=streetcoverstore&utm_medium=footer&utm_campaign=development"
                target={'_blank'}
                rel="noreferrer"
                className={`${classes.link} ${classes.developer}`}
            >
                Создание сайта «Рядышком»</a>
        </footer>
    );
};