import React from 'react';
import classes from './Contacts.module.css';
import { SectionHeading } from '../../components/UI/section-heading/SectionHeading';
import { Footer } from '../../components/UI/footer/Footer';
import { Map, Placemark, YMaps } from 'react-yandex-maps';
import placeMarkImage from '../../images/contacts-map-marker.svg';
import { Social } from '../../components/UI/social/Social';
import { Seo } from '../../components/Seo';

export const Contacts = () => {
    return (
        <>
            <section className={classes.Contacts}>
                <div className={classes.wrapper}>
                    <div className={classes.container}>
                        <SectionHeading
                            title={'Контакты'}
                            description={'Россия, Московская область, Ленинский городской округ, деревня Андреевское, территория Индустриального парка'}
                        />
                        <span className={classes.hours}>ПН - ПТ, 10:00 - 19:00</span>
                    </div>
                    <div className={classes.social}>
                        <Social />
                    </div>
                </div>
                <YMaps>
                    <Map
                        state={{ center: [55.549401, 37.906037], zoom: 15 }}
                        className={classes.map}
                    >
                        <Placemark
                            geometry={[55.550707, 37.907378]}
                            options={{
                                iconLayout: 'default#image',
                                iconImageHref: placeMarkImage,
                                iconImageSize: [100, 100]
                            }}
                        />
                    </Map>
                </YMaps>
                <Footer />
            </section>
            <Seo
                title={'Контакты'}
                description={'Адрес производства: Россия, Московская область, Ленинский городской округ, деревня Андреевское, территория Индустриального парка'}
                keywords={'уличные ковры контакты, уличные ковры адрес, уличные ковры телефон, уличные ковры почта, компания уличные ковры'}
            />
        </>
    );
};