import React, { useEffect, useState } from 'react';
import classes from './ProductForm.module.css';
import { object, string } from 'yup';
import axios from 'axios';
import { Formik } from 'formik';
import { Button } from '../button/Button';
import { phone } from '../../../helpers/constants';

export const ProductForm = ({ product, isModalForm, isVisible, setIsVisible }) => {
    const API_URL = 'https://api.streetcoverstore.ru/wp-json';
    const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
    const PHONE_REGEX = /^\+?\d*$/;
    const FORM_ID = isModalForm ? '33' : '5';
    const [state, setState] = useState('');
    let isSubmitError = state === 'Ошибка!';
    let isSubmitSuccess = state === 'Успешно!';

    useEffect(() => {
        setState(null)
    }, [isVisible, setIsVisible]);

    const dataFromForm = (json) => {
        try {
            const data = new FormData();

            for (let key in json) {
                data.append(key, json[key]);
            }
            return data
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }

    const validationSchema = object({
        formName: string()
            .min(2, 'Минимум 2 символа')
            .required('Укажите имя или название организации'),
        formEmail: isModalForm ? null : string()
            .min(7, 'Минимум 7 символов')
            .matches(EMAIL_REGEX, 'Некорректная почта')
            .required('Укажите электронную почту'),
        formPhone: string()
            .matches(PHONE_REGEX, 'Некорректный номер телефона')
            .min(10, 'Минимум 10 цифр')
            .required('Укажите телефон')
    });

    return (
        <Formik
            initialValues={{
                formHidden: `${product}`,
                formName: '',
                formEmail: '',
                formPhone: '',
                formMessage: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm, setFormikState }) => {
                const onFormSubmit = async () => {

                    try {
                        await axios({
                            url: `${API_URL}/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                            },
                            method: 'POST',
                            data: dataFromForm(values)
                        })
                        setState('Успешно!');
                        setSubmitting(false);
                        resetForm();
                    }
                    catch (error) {
                        setState('Ошибка!');
                        setSubmitting(false);
                        setTimeout(() => {
                            setState(null);
                        }, 10000)
                    }
                };

                void onFormSubmit();
            }}
        >
            {({
                  dirty,
                  isValid,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  handleReset,
              }) => (
                <form
                    className={classes.form}
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <input
                        type="hidden"
                        defaultValue={product}
                        name="formHidden"
                        id="formHidden"
                    />
                    <fieldset className={classes.fieldset}>
                        <div className={classes.field}>
                            <input
                                className={errors.formName && touched.formName ? `${classes.input} ${classes.error}` : classes.input}
                                type="text"
                                name="formName"
                                id="formName"
                                placeholder={'ФИО/Организация *'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.formName}
                            />
                            <div className={classes.errorContainer}>
                                {errors.formName && touched.formName
                                    ? <span
                                        className={classes.errorMessage}
                                    >{errors.formName}</span>
                                    : null}
                            </div>
                        </div>

                        {isModalForm ? null
                            : <div className={classes.field}>
                                <input
                                    className={errors.formEmail && touched.formEmail ? `${classes.input} ${classes.error}` : classes.input}
                                    type="email"
                                    name="formEmail"
                                    id="formEmail"
                                    placeholder={'Эл. почта *'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.formEmail}
                                />
                                <div className={classes.errorContainer}>
                                    {errors.formEmail && touched.formEmail
                                        ? <span
                                            className={classes.errorMessage}
                                        >{errors.formEmail}</span>
                                        : null}
                                </div>
                            </div>}

                        <div className={classes.field}>
                            <input
                                className={errors.formPhone && touched.formPhone ? `${classes.input} ${classes.error}` : classes.input}
                                type="text"
                                name="formPhone"
                                id="formPhone"
                                placeholder={'Телефон *'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.formPhone}
                            />
                            <div className={classes.errorContainer}>
                                {errors.formPhone && touched.formPhone
                                    ? <span
                                        className={classes.errorMessage}
                                    >{errors.formPhone}</span>
                                    : null}
                            </div>
                        </div>
                    </fieldset>
                    <div className={classes.controls}>
                        <fieldset className={classes.fieldset}>
                            <div className={classes.field}>
                                    <textarea
                                        name="formMessage"
                                        id="formMessage"
                                        placeholder={'Комментарий с указанием количества и прочей информацией'}
                                        rows="1"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.formMessage}
                                        className={classes.textarea}
                                    />
                            </div>
                        </fieldset>
                        <fieldset className={classes.fieldset}>
                            <div className={classes.buttonsContainer}>
                                <Button
                                    type="button"
                                    onClick={handleReset}
                                    disabled={!dirty || isSubmitting}
                                >
                                    {'Очистить'}
                                </Button>
                                <Button
                                    isPrimary
                                    type="submit"
                                    disabled={!(dirty && isValid)}
                                >
                                    {isSubmitting
                                        ? 'Отправка ...'
                                        : state ? state : 'Отправить запрос'}
                                </Button>
                            </div>
                            <div className={classes.stateContainer}>
                                {isSubmitError ?
                                    <p className={classes.stateErrorMessage}>
                                        Не удалось отправить запрос. Попробуйте позже или позвоните по телефону {phone}
                                    </p>
                                    : isSubmitSuccess ?
                                        <p className={classes.stateSuccessMessage}>
                                            Спасибо! Свяжемся с Вами в ближайшее время.
                                        </p> : null}
                            </div>
                        </fieldset>
                    </div>
                </form>)}
        </Formik>
    );
};