import React from 'react';
import classes from './Gallery.module.css';

export const Gallery = ({ topImage, bottomImage, isReverse }) => {
    return (
        <div
            className={
                isReverse
                    ? `${classes.Gallery} ${classes.reverse}`
                    : `${classes.Gallery} ${classes.normal}`
            }
        >
            <img
                className={
                    isReverse
                        ? `${classes.image} ${classes.bottomReverse}`
                        : `${classes.image} ${classes.top}`
                }
                src={topImage.link}
                alt={topImage.alt}
            />
            <img
                className={
                    isReverse
                        ? `${classes.image} ${classes.topReverse}`
                        : `${classes.image} ${classes.bottom}`
                }
                src={bottomImage.link}
                alt={bottomImage.alt}
            />
        </div>
    );
};