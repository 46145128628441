import React from 'react';
import classes from './TextWithIcon.module.css';

export const TextWithIcon = ({ icon, text }) => {
    return (
        <div className={classes.TextWithIcon}>
            <img src={icon.link} alt={icon.alt} className={classes.icon} />
            <ul className={classes.text}>
                {Object.keys(text).map(item => {
                    return <li key={item}>{text[item]}</li>
                })}
            </ul>
        </div>
    );
};