import React from 'react';
import classes from './Works.module.css';
import { SectionHeading } from '../../components/UI/section-heading/SectionHeading';
import workOne from '../../images/works-1.jpg';
import workTwo from '../../images/works-2.jpg';
import workThree from '../../images/works-3.jpg';
import workFour from '../../images/works-4.jpg';
import workFive from '../../images/works-5.jpg';
import workSix from '../../images/works-6.jpg';
import workSeven from '../../images/works-7.jpg';
import workEight from '../../images/works-8.jpg';
import workNine from '../../images/works-9.jpg';
import workTen from '../../images/works-10.jpg';
import workEleven from '../../images/works-11.jpg';
import workTwelve from '../../images/works-12.jpg';
import { Seo } from '../../components/Seo';

export const Works = () => {
    return (
        <>
            <section className={classes.Works}>
                <div className={classes.title}>
                    <SectionHeading
                        title={'Наши работы'}
                        description={'Готовые объекты, бережно реализованные нашей командой'}
                    />
                </div>
                <img src={workOne} alt="Дворовая площадка" className={classes.one} />
                <img src={workTwo} alt="Крытый стадион" className={classes.two} />
                <img src={workThree} alt="Двор" className={classes.three} />
                <img src={workFour} alt="Дворовая площадка" className={classes.four} />
                <img src={workFive} alt="Двор с бассейном" className={classes.five} />
                <img src={workSix} alt="Дворовая площадка" className={classes.six} />
                <img src={workSeven} alt="Двор" className={classes.seven} />
                <img src={workEight} alt="Двор многоэтажки" className={classes.eight} />
                <img src={workNine} alt="Цветные листы с покрытием" className={classes.nine} />
                <img src={workTen} alt="Частная площадка" className={classes.ten} />
                <img src={workEleven} alt="Частный двор" className={classes.eleven} />
                <img src={workTwelve} alt="Частная площадка" className={classes.twelve} />
            </section>
            <Seo
                title={'Наши работы'}
                description={'Готовые объекты, бережно реализованные нашей командой'}
                keywords={'Уличные покрытия фото, уличные покрытия работы, уличные ковры работы, благоустройство компания, благоустройство уличные покрытия'}
            />
        </>
    );
};