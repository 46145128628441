import React from 'react';
import classes from './Products.module.css';
import { SectionHeading } from '../../components/UI/section-heading/SectionHeading';
import { ProductCard } from '../../components/UI/product-card/ProductCard';
import safety from '../../images/products-safety.jpg';
import acrylic from '../../images/products-acrylic.jpg';
import stone from '../../images/products-stone.jpg';
import { CircleText } from '../../components/UI/circle-text/CircleText';
import { Seo } from '../../components/Seo';

export const Products = () => {
    return (
        <>
            <section className={classes.Products}>
                <div className={classes.container}>
                    <CircleText />
                </div>
                <SectionHeading
                    title={'Продукция'}
                    description={'Уличные покрытия от STREET COVER STORE отличаются высоким качеством и надёжностью'}
                />
                <div className={classes.grid}>
                    <ProductCard
                        link={'safety'}
                        title={'Безопасное покрытие'}
                        image={safety}
                    />
                    <ProductCard
                        link={'acrylic'}
                        title={'Акриловое покрытие'}
                        image={acrylic}
                    />
                    <ProductCard
                        link={'stone'}
                        title={'Каменный ковёр'}
                        image={stone}
                    />
                </div>
            </section>
            <Seo
                title={'Продукция'}
                description={'Уличные покрытия от STREET COVER STORE отличаются высоким качеством и надёжностью'}
                keywords={'травмобезопасное уличное покрытие, безопасное уличное покрытие, каменный уличный ковёр, акриловое уличное покрытие, детское уличное покрытие'}
            />
        </>
    );
};