import React from 'react';
import classes from './Product.module.css';
import topImage from '../../images/product-gallery-safety-top.jpg';
import bottomImage from '../../images/product-gallery-safety-bottom.jpg';
import { Gallery } from '../../components/UI/gallery/Gallery';
import { SectionHeading } from '../../components/UI/section-heading/SectionHeading';
import { ProductForm } from '../../components/UI/product-form/ProductForm';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { CityAndPrice } from '../../components/UI/city-and-price/CityAndPrice';
import { TextWithIcon } from '../../components/UI/text-with-icon/TextWithIcon';
import safety from '../../images/product-icon-safety.svg';
import ecoFriendly from '../../images/product-icon-eco-friendly.svg';
import sunAndCloud from '../../images/product-icon-sun-and-cloud.svg';
import palette from '../../images/product-icon-palette.svg';
import snappingFingers from '../../images/product-icon-snapping-fingers.svg';
import basketball from '../../images/product-icon-basketball.svg';
import smilingBaby from '../../images/product-icon-smiling-baby.svg';
import parkBench from '../../images/product-icon-park-bench.svg';
import umbrellaTable from '../../images/product-icon-umbrella-table.svg';
import pier from '../../images/product-icon-pier.svg';
import screwdriverAndWrench from '../../images/product-icon-screwdriver-and-wrench.svg';
import warehouse from '../../images/product-icon-warehouse.svg';
import helicopter from '../../images/product-icon-helicopter.svg';
import horse from '../../images/product-icon-horse.svg';
import { useCheckMobileScreen } from '../../hooks/useCheckMobileScreen';
import { breakPointMobile } from '../../helpers/constants';
import { Seo } from '../../components/Seo';
import { centeredInScrollView } from '../../helpers/constants';

export const Safety = () => {
    const isMobile = useCheckMobileScreen(breakPointMobile);

    return (
        <>
            <section className={(classes.Product)}>
                {isMobile ? null
                    : <Gallery
                        topImage={{
                            link: topImage,
                            alt: 'Красное травмобезопасное покрытие на беговой дорожке стадиона'
                        }}
                        bottomImage={{
                            link: bottomImage,
                            alt: 'Разноцветное травмобезопасное покрытие на дворовой баскетбольной площадке'
                        }}
                    />}
                <div className={classes.container}>
                    <SectionHeading
                        title={'Безопасное покрытие'}
                        description={'Это травмобезопасное, метеоустойчивое, лёгкое в эксплуатации покрытие из измельченных старых автомобильных покрышек, смешенное с полиуретановым клеем, модификаторами и пигментом'}
                    />

                    {isMobile ? <Gallery
                        topImage={{
                            link: topImage,
                            alt: 'Красное травмобезопасное покрытие на беговой дорожке стадиона'
                        }}
                        bottomImage={{
                            link: bottomImage,
                            alt: 'Разноцветное травмобезопасное покрытие на дворовой баскетбольной площадке'
                        }}
                    /> : null}

                    <ProductForm
                        product={'Безопасное покрытие'}
                    />
                    <Tabs
                        className={classes.tabsContainer}
                        selectedTabClassName={classes.tabActive}
                        selectedTabPanelClassName={classes.tabContent}
                    >
                        <TabList className={classes.tabs} onClick={centeredInScrollView}>
                            <Tab className={classes.tabName}>Стоимость</Tab>
                            <Tab className={classes.tabName}>Характеристики</Tab>
                            <Tab className={classes.tabName}>Применение</Tab>
                            <Tab className={classes.tabName}>Гарантия</Tab>
                        </TabList>

                        <TabPanel className={classes.tabPrice}>
                            <div className={classes.cityAndPrice}>
                                <CityAndPrice
                                    data={{
                                        city: 'Москва',
                                        sbr: 'SBR — от 1210 руб.',
                                        epdm: 'EPDM — от 2420 руб.',
                                        tile: 'Плитка — от 1350 руб.'
                                    }}
                                />
                                <CityAndPrice
                                    data={{
                                        city: isMobile ? 'Московская обл.' : 'Московская область',
                                        sbr: 'SBR — от 1210 руб.',
                                        epdm: 'EPDM — от 2420 руб.',
                                        tile: 'Плитка — от 1350 руб.'
                                    }}
                                />
                            </div>
                            <ul className={classes.footnotes}>
                                <li className={classes.info}>SBR — резина, изготовленная на основе каучука</li>
                                <li className={classes.info}>EPDM-крошка — инновационный продукт, отличающийся
                                    прочностью и долговечностью
                                </li>
                                <li>* стоимость указана за 1 кв. м толщиной 10 мм</li>
                                <li>** стоимость доставки исчисляется по тарифам транспортной компании на дату
                                    заключения
                                    договора
                                </li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <Tabs
                                className={classes.tabNav}
                                selectedTabClassName={classes.tabNavActive}
                                selectedTabPanelClassName={classes.tabNavContent}
                            >
                                <TabList className={classes.tabNavList}>
                                    <Tab className={classes.tabNavName}>Травмобезопасность</Tab>
                                    <Tab className={classes.tabNavName}>Экологичность</Tab>
                                    <Tab className={classes.tabNavName}>Метеоустойчивость</Tab>
                                    <Tab className={classes.tabNavName}>Разнообразие</Tab>
                                    <Tab className={classes.tabNavName}>Лёгкость в эксплуатации</Tab>
                                </TabList>
                                <TabPanel>
                                    <TextWithIcon
                                        icon={{ link: safety, alt: 'Иконка с изображением мужчины в каске' }}
                                        text={{ description: 'Шероховатая поверхность снижает риск падения, а амортизирующие свойства поглощают удар.' }}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <TextWithIcon
                                        icon={{
                                            link: ecoFriendly,
                                            alt: 'Иконка с изображением домика, с листом внутри'
                                        }}
                                        text={{
                                            description: 'Покрытие безопасно для человека. Каучук, связующие компоненты, модификаторы ' +
                                                'и цветовой пигмент не выделяют вредных веществ и не имеют неприятного ' +
                                                'запаха, это гипоаллергенный материал.'
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <TextWithIcon
                                        icon={{
                                            link: sunAndCloud,
                                            alt: 'Иконка с изображением солнца за тучей с каплями дождя'
                                        }}
                                        text={{
                                            description: 'Покрытия на открытых площадках способны выдерживать перепады температур ' +
                                                'от -30° до +50°, высыхать после дождя за 10 минут, а пигмент ' +
                                                'сохранить свою яркость под воздействием УФ излучения.'
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <TextWithIcon
                                        icon={{
                                            link: palette,
                                            alt: 'Иконка с изображением кисти, лежащей на палитре'
                                        }}
                                        text={{
                                            description: 'Нанести разметку для футбольного поля или нарисовать банки с супом Кэмпбелл? ' +
                                                'Выбирайте цвет.'
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <TextWithIcon
                                        icon={{
                                            link: snappingFingers,
                                            alt: 'Иконка с изображением щелчка пальцами'
                                        }}
                                        text={{
                                            description: 'Не требует тщательного ухода, для уборки достаточно воды или мягкой ' +
                                                'щетки. На покрытии не размножаются грибки и микроорганизмы, сквозь него ' +
                                                'не растет трава, а грызуны находят его невкусным.'
                                        }}
                                    />
                                </TabPanel>
                            </Tabs>
                        </TabPanel>
                        <TabPanel className={classes.use}>
                            <TextWithIcon
                                icon={{
                                    link: basketball,
                                    alt: 'Иконка с изображением мяча'
                                }}
                                text={{ description: 'Спортивные площадки и стадионы' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: smilingBaby,
                                    alt: 'Иконка с изображением улыбающегося ребёнка'
                                }}
                                text={{ description: 'Детские игровые площадки' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: parkBench,
                                    alt: 'Иконка с изображением двух деревьев и скамейки'
                                }}
                                text={{ description: 'Зоны отдыха, пешеходные дорожки' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: umbrellaTable,
                                    alt: 'Иконка с изображением двух стульев под уличным зонтом'
                                }}
                                text={{ description: 'Входные группы, зоны паркинга, патио' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: pier,
                                    alt: 'Иконка с изображением лестницы, погруженной в воду'
                                }}
                                text={{ description: 'Причалы, зоны возле бассейнов, фонтанов, катков' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: warehouse,
                                    alt: 'Иконка с изображением хранилища, закрытого автоматической гаражной дверью'
                                }}
                                text={{ description: 'Производственные и складские помещения' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: screwdriverAndWrench,
                                    alt: 'Иконка с изображением скрещенных гаечного ключа и отвёртки'
                                }}
                                text={{ description: 'Автомастерские и сервисные центры' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: helicopter,
                                    alt: 'Иконка с изображением вертолёта'
                                }}
                                text={{ description: 'Вертолётные площадки' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: horse,
                                    alt: 'Иконка с изображением лошади'
                                }}
                                text={{ description: 'Конюшни' }}
                            />
                        </TabPanel>
                        <TabPanel className={classes.warranty}>
                            <h2 className={classes.warrantyHeading}>STREET COVER STORE ценит свою репутацию!</h2>
                            <p className={classes.warrantySubheading}>Если в течение 24 месяцев при соблюдении
                                рекомендаций
                                произойдет деформация покрытия, мы
                                безвозмездно проведём реставрационные работы.</p>
                        </TabPanel>
                    </Tabs>
                </div>
            </section>
            <Seo
                title={'Безопасное покрытие'}
                description={'Травмобезопасное, метеоустойчивое, лёгкое в эксплуатации покрытие из измельченных старых автомобильных покрышек, смешенное с полиуретановым клеем, модификаторами и пигментом'}
                keywords={'уличное покрытие для детской площадки, уличное резиновое покрытие, резиновое покрытие, безопасное уличное покрытие, травмобезопасное уличное покрытие, мягкое уличное покрытие'}
            />
        </>
    );
};