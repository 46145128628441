import React from 'react';
import classes from './Social.module.css';
import { useCheckMobileScreen } from '../../../hooks/useCheckMobileScreen';
import { breakPointMobile } from '../../../helpers/constants';

export const Social = () => {
    const isMobile = useCheckMobileScreen(breakPointMobile)
    return (
        <>
            {/*eslint-disable-next-line*/}
            {isMobile && <a
                href="https://wa.me/message/W6IN3K7KO34YL1" target={'_blank'} rel="noreferrer"
                className={`${classes.link} ${classes.whatsapp}`}
            />}
            {/*eslint-disable-next-line*/}
            <a
                href="https://vk.com/streetcoverstore" target={'_blank'} rel="noreferrer"
                className={`${classes.link} ${classes.vk}`}
            />
            {/*eslint-disable-next-line*/}
            <a
                href="https://t.me/streetcoverstore" target={'_blank'} rel="noreferrer"
                className={`${classes.link}  ${classes.telegram}`}
            />
        </>
    );
};