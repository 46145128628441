import React, { useState, useEffect } from 'react';
import classes from './HeaderMobile.module.css';
import { Logo } from '../logo/Logo';
import { Button } from '../button/Button';
import { useLocation } from 'react-router-dom';

export const HeaderMobile = ({ modal, setModal, isVisible, setIsVisible }) => {
    const location = useLocation();
    const [currentLocation] = useState(location);

    useEffect(() => {
        if (location !== currentLocation && isVisible) {
            setTimeout(() => {
                setIsVisible(false);
            }, 300)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, currentLocation, setIsVisible]);

    return (
        <div className={classes.HeaderMobile}>
            <Logo />
            <div className={classes.buttonWrapper}>
                <Button disabled={modal} onClick={() => setModal(true)}>Заказать звонок</Button>
            </div>
            <button
                onClick={() => setIsVisible(!isVisible)} className={isVisible ? classes.close : classes.hamburger}
            />
        </div>
    );
};