import React from 'react';
import { createPortal } from 'react-dom';
import classes from './Modal.module.css';

export const Modal = ({ children, isVisible, setIsVisible, title }) => {
    const rootClasses = [classes.modal];

    if (isVisible) {
        rootClasses.push(classes.active, 'in')
    }

    return isVisible &&
        createPortal(
            <div className={rootClasses.join(' ')} onClick={() => setIsVisible(false)}>
                <div className={classes.content} onClick={e => e.stopPropagation()}>
                    <p className={classes.title}>{title}</p>
                    {children}
                    <button className={classes.close} onClick={() => setIsVisible(false)} />
                </div>
            </div>, document.body
        )
};