import React from 'react';
import classes from './CityAndPrice.module.css';

export const CityAndPrice = ({ data }) => {
    return (
        <div className={classes.CityAndPrice}>
            <p className={classes.city}>{data.city}</p>
            <ul className={classes.price}>
                {Object.keys(data).slice(1).map(item => {
                    return <li key={item}>
                        {data[item]}
                    </li>
                })}
            </ul>
        </div>
    );
};