import React from 'react';
import classes from './Navigation.module.css';
import { NavLink } from 'react-router-dom';

export const Navigation = () => {
    return (
        <nav className={classes.Navigation}>
            <NavLink
                end
                to={'/products'}
                className={(
                    { isActive }) => (
                    isActive
                        ? classes.active
                        : classes.link)}
            >Продукция</NavLink>
            <NavLink
                to={'/products/safety'}
                className={(
                    { isActive }) => (
                    isActive
                        ? classes.active
                        : classes.link)}
            >Безопасное покрытие</NavLink>
            <NavLink
                to={'/products/acrylic'}
                className={(
                    { isActive }) => (
                    isActive
                        ? classes.active
                        : classes.link)}
            >Акриловое покрытие</NavLink>
            <NavLink
                to={'/products/stone'}
                className={(
                    { isActive }) => (
                    isActive
                        ? classes.active
                        : classes.link)}
            >Каменный ковёр</NavLink>
            <NavLink
                to={'/works'}
                className={(
                    { isActive }) => (
                    isActive
                        ? classes.active
                        : classes.link)}
            >Наши работы</NavLink>
            <NavLink
                to={'/contacts'}
                className={(
                    { isActive }) => (
                    isActive
                        ? classes.active
                        : classes.link)}
            >Контакты</NavLink>
        </nav>
    );
};