export const phone = '+7 (499) 390-53-15';
export const phoneHref = '+74993905315'

export const breakPointMobile = 1279;

export const userHeight = () => {
    const app = document.documentElement;
    app.style.setProperty('--user-height', `${window.innerHeight}px`);
    window.addEventListener('resize', userHeight);
}

export const centeredInScrollView = e => {
    e.target.scrollIntoView({ inline: 'center', block: 'nearest' });
}
