import React from 'react';
import classes from './Product.module.css';
import topImage from '../../images/product-gallery-acrylic-top.jpg';
import bottomImage from '../../images/product-gallery-acrylic-bottom.jpg';
import { Gallery } from '../../components/UI/gallery/Gallery';
import { SectionHeading } from '../../components/UI/section-heading/SectionHeading';
import { ProductForm } from '../../components/UI/product-form/ProductForm';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { CityAndPrice } from '../../components/UI/city-and-price/CityAndPrice';
import { TextWithIcon } from '../../components/UI/text-with-icon/TextWithIcon';
import safety from '../../images/product-icon-safety.svg';
import chainLinks from '../../images/product-icon-chain-links.svg';
import versatility from '../../images/product-icon-versatility.svg';
import cyclist from '../../images/product-icon-cyclist.svg';
import soccerBall from '../../images/product-icon-soccer-ball.svg';
import rollerSkates from '../../images/product-icon-roller-skates.svg';
import volleyball from '../../images/product-icon-volleyball.svg'
import palette from '../../images/product-icon-palette.svg';
import basketball from '../../images/product-icon-basketball.svg';
import { useCheckMobileScreen } from '../../hooks/useCheckMobileScreen';
import { breakPointMobile } from '../../helpers/constants';
import { Seo } from '../../components/Seo';
import { centeredInScrollView } from '../../helpers/constants';

export const Acrylic = () => {
    const isMobile = useCheckMobileScreen(breakPointMobile);
    return (
        <>
            <section className={(classes.Product)}>
                {isMobile ? null : <Gallery
                    isReverse
                    topImage={{
                        link: topImage,
                        alt: 'Синее акриловое покрытие'
                    }}
                    bottomImage={{
                        link: bottomImage,
                        alt: 'Теннисные ракетки с мячом на синем акриловом покрытии'
                    }}
                />}
                <div className={classes.container}>
                    <SectionHeading
                        title={'Акриловое покрытие'}
                        description={'Акрил или термопластичная смола обладает способностью длительное время сохранять упругость вне зависимости от погодных условий, что делает его одним из самых практичных покрытий для спортивных площадок'}
                    />
                    {isMobile ? <Gallery
                        isReverse
                        topImage={{
                            link: topImage,
                            alt: 'Синее акриловое покрытие'
                        }}
                        bottomImage={{
                            link: bottomImage,
                            alt: 'Теннисные ракетки с мячом на синем акриловом покрытии'
                        }}
                    /> : null}
                    <ProductForm
                        product={'Акриловое покрытие'}
                    />
                    <Tabs
                        className={classes.tabsContainer}
                        selectedTabClassName={classes.tabActive}
                        selectedTabPanelClassName={classes.tabContent}
                    >
                        <TabList className={classes.tabs} onClick={centeredInScrollView}>
                            <Tab className={classes.tabName}>Стоимость</Tab>
                            <Tab className={classes.tabName}>Характеристики</Tab>
                            <Tab className={classes.tabName}>Применение</Tab>
                            <Tab className={classes.tabName}>Гарантия</Tab>
                        </TabList>

                        <TabPanel className={classes.tabPrice}>
                            <div className={classes.cityAndPrice}>
                                <CityAndPrice
                                    data={{
                                        city: 'Москва',
                                        five: '5 слоёв — от 2400 руб.',
                                        seven: '7 слоёв — от 2900 руб.',
                                        nine: '9 слоёв — от 3700 руб.'
                                    }}
                                />
                                <CityAndPrice
                                    data={{
                                        city: isMobile ? 'Московская обл.' : 'Московская область',
                                        five: '5 слоёв — от 2550 руб.',
                                        seven: '7 слоёв — от 3150 руб.',
                                        nine: '9 слоёв — от 3850 руб.'
                                    }}
                                />
                            </div>
                            <ul className={classes.footnotes}>
                                <li>* стоимость указана за 1 кв. м толщиной 10 мм</li>
                                <li>** стоимость доставки исчисляется по тарифам транспортной компании на дату
                                    заключения
                                    договора
                                </li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <Tabs
                                className={classes.tabNav}
                                selectedTabClassName={classes.tabNavActive}
                                selectedTabPanelClassName={classes.tabNavContent}
                            >
                                <TabList className={classes.tabNavList}>
                                    <Tab className={classes.tabNavName}>Травмобезопасность</Tab>
                                    <Tab className={classes.tabNavName}>Износостойкость</Tab>
                                    <Tab className={classes.tabNavName}>Универсальность</Tab>
                                    <Tab className={classes.tabNavName}>Эстетика</Tab>
                                </TabList>
                                <TabPanel>
                                    <TextWithIcon
                                        icon={{ link: safety, alt: 'Иконка с изображением мужчины в каске' }}
                                        text={{ description: 'Покрытие остается упругим и эластичным при температуре от -10° до +50°.' }}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <TextWithIcon
                                        icon={{
                                            link: chainLinks,
                                            alt: 'Иконка с изображением не рвущихся звеньев цепи'
                                        }}
                                        text={{
                                            description: 'При правильной эксплуатации срок службы покрытия достигает 5 лет без реставрационных работ. Подходит как для открытых, так и для закрытых площадок.'
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <TextWithIcon
                                        icon={{
                                            link: versatility,
                                            alt: 'Иконка с изображением теннисной ракетки, принимающей теннисный мяч'
                                        }}
                                        text={{
                                            description: 'Благодаря увеличению или уменьшению доли кварцевого песка в финишном слое покрытия, можно рассчитать параметры отскока мяча, приблизив корт к травяному или грунтовому.'
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <TextWithIcon
                                        icon={{
                                            link: palette,
                                            alt: 'Иконка с изображением кисти, лежащей на палитре'
                                        }}
                                        text={{
                                            description: 'Акрил выглядит красиво и имеет широкую палитру цветов. На покрытие может быть нанесен любой рисунок.'
                                        }}
                                    />
                                </TabPanel>
                            </Tabs>
                        </TabPanel>
                        <TabPanel className={classes.use}>
                            <TextWithIcon
                                icon={{
                                    link: versatility,
                                    alt: 'Иконка с изображением теннисной ракетки, принимающей теннисный мяч'
                                }}
                                text={{ description: 'Большой теннис' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: basketball,
                                    alt: 'Иконка с изображением баскетбольного мяча'
                                }}
                                text={{ description: 'Стрит-баскет' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: cyclist,
                                    alt: 'Иконка с изображением велосипедиста'
                                }}
                                text={{ description: 'Велодорожки' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: soccerBall,
                                    alt: 'Иконка с изображением футбольного мяча'
                                }}
                                text={{ description: 'Мини-футбол' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: rollerSkates,
                                    alt: 'Иконка с изображением роликового конька'
                                }}
                                text={{ description: 'Роллер-дромы' }}
                            />
                            <TextWithIcon
                                icon={{
                                    link: volleyball,
                                    alt: 'Иконка с изображением волейбольного мяча'
                                }}
                                text={{ description: 'Волейбол' }}
                            />
                        </TabPanel>
                        <TabPanel className={classes.warranty}>
                            <h2 className={classes.warrantyHeading}>STREET COVER STORE ценит свою репутацию!</h2>
                            <p className={classes.warrantySubheading}>Если в течение 24 месяцев при соблюдении
                                рекомендаций
                                произойдет деформация покрытия, мы
                                безвозмездно проведём реставрационные работы.</p>
                        </TabPanel>
                    </Tabs>
                </div>
            </section>
            <Seo
                title={'Акриловое покрытие'}
                description={'Акрил или термопластичная смола обладает способностью длительное время сохранять упругость вне зависимости от погодных условий, что делает его одним из самых практичных покрытий для спортивных площадок'}
                keywords={'акриловое уличное покрытие, акриловое покрытие для площадки, спортивная площадка акриловое покрытие'}
            />
        </>
    );
};