import React from 'react';
import classes from './Legal.module.css';
import { SectionHeading } from '../../components/UI/section-heading/SectionHeading';
import { Footer } from '../../components/UI/footer/Footer';
import { Seo } from '../../components/Seo';

export const Terms = () => {
    return (
        <>
            <section className={classes.Legal}>
                <SectionHeading
                    title={'Правила использования сайта'}
                    description={'Пожалуйста, внимательно ознакомьтесь с информацией о правилах использования сайта streetcoverstore.ru'}
                />
                <article className={classes.container}>
                    <p>Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между ИП Кочергиной
                        Евгенией
                        Станиславовной (Администрация) с одной стороны и пользователем сайта с
                        другой.</p>
                    <p>Сайт https://streetcoverstore.ru не является средством массовой информации.</p>
                    <p>Используя сайт, Вы соглашаетесь с условиями данного соглашения.</p>
                    <p>Если Вы не согласны с условиями данного соглашения, не используйте сайт
                        https://streetcoverstore.ru!</p>
                    <h2>Права и обязанности сторон</h2>
                    <p>Пользователь имеет право:</p>
                    <ol>
                        <li>осуществлять поиск информации на сайте</li>
                        <li>получать информацию на сайте</li>
                        <li>использовать информацию сайта в личных некоммерческих целях</li>
                    </ol>
                    <p>Администрация имеет право:</p>
                    <ol>
                        <li>по своему усмотрению и необходимости создавать, изменять,
                            отменять
                            правила
                        </li>
                        <li>ограничивать доступ к любой информации на сайте</li>
                    </ol>
                    <p>Пользователь обязуется:</p>
                    <ol>
                        <li>не нарушать работоспособность сайта</li>
                        <li>не использовать скрипты (программы) для автоматизированного
                            сбора
                            информации и/или взаимодействия с Сайтом и его Сервисами
                        </li>
                    </ol>
                    <p>Администрация обязуется:</p>
                    <ol>
                        <li>поддерживать работоспособность сайта за исключением случаев,
                            когда
                            это невозможно по независящим от Администрации причинам
                        </li>
                        <li>предоставить всю доступную информацию о Пользователе
                            уполномоченным
                            на то органам государственной власти в случаях, установленных законом
                        </li>
                    </ol>
                    <h2>Ответственность сторон</h2>
                    <ol>
                        <li>пользователь лично несет полную ответственность за
                            распространяемую
                            им информацию
                        </li>
                        <li>администрация не несет никакой ответственности за достоверность
                            информации, скопированной из других источников
                        </li>
                        <li>администрация не несёт ответственность за несовпадение ожидаемых
                            Пользователем и реально полученных услуг
                        </li>
                        <li>администрация не несет никакой ответственности за услуги,
                            предоставляемые третьими лицами
                        </li>
                        <li>в случае возникновения форс-мажорной ситуации (боевые действия,
                            чрезвычайное положение, стихийное бедствие и т. д.)
                        </li>
                    </ol>
                    <p>Администрация не гарантирует сохранность информации, размещённой Пользователем, а также
                        бесперебойную
                        работу информационного ресурса</p>
                    <h2>Условия действия Соглашения</h2>
                    <p>Данное Соглашение вступает в силу при любом использовании данного сайта. Соглашение действует
                        бессрочно. Администрация оставляет за собой право в одностороннем порядке изменять данное
                        соглашение
                        по своему усмотрению. Администрация не оповещает пользователей об изменении в Соглашении.</p>
                </article>
                <Footer />
            </section>
            <Seo
                title={'Правила использования сайта'}
                description={'Пожалуйста, внимательно ознакомьтесь с информацией о правилах использования сайта streetcoverstore.ru'}
                keywords={'пользовательское соглашение streetcoverstore.ru'}
            />
        </>
    );
};