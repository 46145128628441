import React from 'react';
import classes from './Button.module.css';

export const Button = ({ isPrimary, children, ...props }) => {
    return (
        <button
            {...props}
            className={
                isPrimary
                    ? `${classes.Button} ${classes.primary}`
                    : `${classes.Button} ${classes.secondary}`
            }
        >
            {children}
        </button>
    );
};