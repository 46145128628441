import React from 'react';
import classes from './ProductCard.module.css';
import { Link } from 'react-router-dom';

export const ProductCard = ({ link, title, image }) => {
    return (
        <Link to={`/products/${link}`} className={classes.link}>
            <h2 className={classes.heading}>{title}</h2>
            <div className={classes.wrapper}>
                <img src={image} alt={title} className={classes.image} />
            </div>
        </Link>
    );
};