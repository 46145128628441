import { Home } from '../pages/home/Home';
import { Products } from '../pages/products/Products';
import { Safety } from '../pages/product/Safety';
import { Acrylic } from '../pages/product/Acrylic';
import { Stone } from '../pages/product/Stone';
import { Works } from '../pages/works/Works';
import { Contacts } from '../pages/contacts/Contacts';
import { Privacy } from '../pages/legal/Privacy';
import { Terms } from '../pages/legal/Terms';

export const routes = [
    {
        path: '/',
        element: Home
    }, {
        path: '/products',
        element: Products
    }, {
        path: '/products/safety',
        element: Safety
    }, {
        path: '/products/acrylic',
        element: Acrylic
    }, {
        path: '/products/stone',
        element: Stone
    }, {
        path: '/works',
        element: Works
    }, {
        path: '/contacts',
        element: Contacts
    }, {
        path: '/privacy',
        element: Privacy
    }, {
        path: '/terms',
        element: Terms
    }
];