import React, { useState, useEffect } from 'react';
import classes from './App.module.css';
import { Header } from '../UI/header/Header';
// noinspection ES6CheckImport
import { Routes, Route, useLocation } from 'react-router-dom';
import { routes } from '../../helpers/routes';
import { userHeight } from '../../helpers/constants';
import { NotFound } from '../../pages/404/NotFound';
import { Modal } from '../UI/modal/Modal';
import { ProductForm } from '../UI/product-form/ProductForm';
import { useCheckMobileScreen } from '../../hooks/useCheckMobileScreen';
import { breakPointMobile } from '../../helpers/constants';
import { HeaderMobile } from '../UI/header-mobile/HeaderMobile';

function App() {
    userHeight();
    const [modal, setModal] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const isMobile = useCheckMobileScreen(breakPointMobile);
    const location = useLocation();
    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransitionStage] = useState('in');

    useEffect(() => {
        if (location !== displayLocation) setTransitionStage('out');
    }, [location, displayLocation]);

    useEffect(() => {
        window.ym(88831373, 'hit', location.pathname);
    }, [location, displayLocation]);

    return (
        <div
            className={classes.App}
        >
            {isMobile ? <HeaderMobile
                modal={modal} setModal={setModal} isVisible={isSidebarVisible} setIsVisible={setIsSidebarVisible}
            /> : null}
            <Header modal={modal} setModal={setModal} isVisible={isSidebarVisible} />
            <div
                className={isMobile ? classes.container : `${classes.container} ${transitionStage}`}
                onAnimationEnd={() => {
                    if (transitionStage === 'out') {
                        setTransitionStage('in');
                        setDisplayLocation(location);
                    }
                }}
            >
                <Routes location={isMobile ? null : displayLocation}>
                    <Route path={'*'} element={<NotFound modal={modal} setModal={setModal} />} />
                    {routes.map((route, i) => {
                        return (
                            <Route
                                key={i}
                                path={route.path}
                                element={
                                    <route.element />
                                }
                            />
                        );
                    })}
                </Routes>
            </div>
            <Modal
                isVisible={modal}
                setIsVisible={setModal}
                title={'Отправьте ваши данные и мы перезвоним'}
            >
                <ProductForm
                    product={'Обратный звонок'}
                    isModalForm
                    isVisible={modal}
                    setIsVisible={setModal}
                />
            </Modal>
        </div>
    );
}

export default App;