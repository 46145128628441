import React from 'react';
import classes from './Home.module.css';
import { Button } from '../../components/UI/button/Button';
import { useNavigate } from 'react-router-dom';
import { Social } from '../../components/UI/social/Social';
import { Seo } from '../../components/Seo';

export const Home = () => {
    const navigate = useNavigate();
    const goProducts = () => {
        navigate('/products');
    };

    return (
        <>
            <section className={classes.Home}>
                <article className={classes.about}>
                    <div className={classes.wrapper}>
                        <h1 className={classes.heading}>Уличные ковры</h1>
                        <p className={classes.subheading}>Благоустраиваем общественные территории с 2016 года, делая Ваш
                            досуг приятнее</p>
                        <div className={classes.buttonWrapper}>
                            <Button
                                isPrimary
                                onClick={goProducts}
                                type="button"
                            >Продукция</Button>
                        </div>
                    </div>
                </article>
                <div className={classes.accent}></div>
                <div className={classes.social}>
                    <Social />
                </div>
            </section>
            <Seo
                title={'Уличные ковры'}
                description={'Благоустраиваем общественные территории с 2016 года, делая Ваш досуг приятнее'}
                keywords={'уличное покрытие, покрытие для уличной площадки, детские уличные покрытия, покрытие уличное цена, уличное резиновое покрытие, каменный ковёр уличное покрытие, купить уличное покрытие, современные уличные покрытия, спортивное уличное покрытие, акриловое уличное покрытие'}
            />
        </>
    );
};