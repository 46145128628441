import React from 'react';
import classes from './NotFound.module.css';
import { Button } from '../../components/UI/button/Button';
import { Social } from '../../components/UI/social/Social';
import top from '../../images/not-found-top.jpg';
import middle from '../../images/not-found-middle.jpg';
import bottom from '../../images/not-found-bottom.jpg';
import { Seo } from '../../components/Seo';

export const NotFound = ({ modal, setModal }) => {
    return (
        <>
            <section className={classes.NotFound}>
                <div className={classes.imageContainer}>
                    <img className={classes.top} src={top} alt="Верхнее покрытие" />
                    <img className={classes.middle} src={middle} alt="Среднее покрытие" />
                    <img className={classes.bottom} src={bottom} alt="Нижнее покрытие" />
                </div>
                <div className={classes.container}>
                    <div className={classes.textWrapper}>
                        <h1 className={classes.title}>404</h1>
                        <p>Увы, страница, которую Вы искали, не найдена.</p>
                        <p>Есть вопросы? Свяжитесь с нами, отправив запрос или напишите в социальные сети.</p>
                    </div>
                    <div className={classes.buttonWrapper}>
                        <Button isPrimary disabled={modal} onClick={() => setModal(true)}>Отправить запрос</Button>
                    </div>
                    <div className={classes.social}>
                        <Social />
                    </div>
                </div>
            </section>
            <Seo
                title={'404'}
                description={'Страница 404 на streetcoverstore.ru'}
                keywords={'404, не найдено, streetcoverstore.ru'}
            />
        </>
    );
};