import React from 'react';
import classes from './Header.module.css';
import { Logo } from '../logo/Logo';
import { Navigation } from '../navigation/Navigation';
import { Button } from '../button/Button';
import { phone, phoneHref } from '../../../helpers/constants';
import { useCheckMobileScreen } from '../../../hooks/useCheckMobileScreen';
import { breakPointMobile } from '../../../helpers/constants';
import { Link } from 'react-router-dom';

export const Header = ({ modal, setModal, isVisible }) => {
    const isMobile = useCheckMobileScreen(breakPointMobile);
    return (
        <header className={isVisible ? `${classes.Header} ${classes.visible}` : classes.Header}>
            {isMobile ? null : <Logo />}
            <Navigation />
            <div className={classes.wrapper}>
                <div className={classes.contacts}>
                    <a className={`${classes.link} ${classes.phone}`} href={`tel:${phoneHref}`}>{phone}</a>
                    <a
                        className={`${classes.link} ${classes.email}`} href="mailto:order@streetcoverstore.ru"
                    >order@streetcoverstore.ru</a>
                    <Link
                        to={'/contacts'}
                        className={`${classes.link} ${classes.hours}`} href="mailto:order@streetcoverstore.ru"
                    >ПН - ПТ, 10:00 - 19:00</Link>
                    {isMobile ? null : <Button type="button" disabled={modal} onClick={() => setModal(true)}>Заказать
                        звонок</Button>}
                </div>
                {isMobile ? null : <div className={classes.logo} />}
            </div>
            {isMobile ? <div className={classes.logo} /> : null}
        </header>
    );
};
